<template>
  <section class="view">
    <div class="slides slowFade box">
        <div class="slide">
            <img src="../assets/rust001.png" alt="img"/>
        </div>
        <div class="slide">
            <img src="../assets/rust002.png" alt="img"/>
        </div>
        <div class="slide">
            <img src="../assets/rust003.png" alt="img"/>
        </div> 
        <div class="slide">
            <img src="../assets/rust004.png" alt="img"/>
        </div>
        <div class="slide">
            <img src="../assets/rust005.png" alt="img"/>
        </div>        
    </div>
    <div class="box overlay">
      <h1>Welcome to Crusader Games</h1>
      <img id="logo" style="z-index: 1;" alt="Crusader Games logo" src="../assets/logo.png" />      
      <h2>Join our $150 Grand Prize Rust Tournament</h2>
      <h3>Starts Nov 14th 2024 7PM CST</h3>
      <h1>{{ timerText }}</h1>
      <ol>
        <li><a href="https://forms.gle/MZhQAJvMJSapo7HfA" target="_blank">Register Here</a></li>
        <li>Join <a href="https://discord.gg/Q4SVNC4dkp" target="_blank">Discord</a> for Rules and Announcements</li>
        <li>Connect to Server Address below</li>
      </ol>
      <p><span style="font-size: 30px; color: red;">play.crusadergames.net</span></p>
    </div>
    <!-- <div class="container">
      <img id="logo" alt="Crusader Games logo" src="../assets/logo.png" />
      <p style="font-size: 25px;">Welcome to Crusader Games</p>
      <p>Play our free minecraft server at  <span style="color: darkred;">PLAY.CRUSADERGAMES.NET</span></p>
    </div> -->
  </section>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      timerText: 'Loading...',
      targetDate: new Date("2024-11-14T19:00:00").getTime(),
    };
  },
  created() {
    this.updateCountdown();
  },
  methods: {
    updateCountdown(){
      const now = new Date().getTime();
      const distance = this.targetDate - now;

      if (distance < 0){
        this.timerText = "Event Started!";
        return;
      }

      const days = Math.floor(distance / (1000* 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.timerText = `${days}d ${hours}h ${minutes}m ${seconds}s`;
      setTimeout(this.updateCountdown, 1000);
    }
  }};
</script>

<style scoped>
.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.overlay {
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  opacity: 0.8;
}

.slowFade {
    display: flex;
    align-items: flex-start;
    background: #fff;
    height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100%;
}
.slowFade .slide img {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    background: #000;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    opacity: 0;
    transform: scale(1.5) rotate(15deg);
    -webkit-animation: slowFade 32s infinite;
            animation: slowFade 32s infinite;
}
.slowFade .slide:nth-child(3) img {
    -webkit-animation-delay: 8s;
            animation-delay: 8s;
}
.slowFade .slide:nth-child(2) img {
    -webkit-animation-delay: 16s;
            animation-delay: 16s;
}
.slowFade .slide:nth-child(1) img {
    -webkit-animation-delay: 24s;
            animation-delay: 24s;
}

@keyframes slowFade {
    25% {
        opacity: 1;
        transform: scale(1) rotate(0);
    }
    40% {
        opacity: 0;
    }
}

@-webkit-keyframes slowFade {
    25% {
        opacity: 1;
        transform: scale(1) rotate(0);
    }
    40% {
        opacity: 0;
    }
}

.view {
  /* background-image: url("/src/assets/Zombies.png"); */
  /* background-image: url("/src/assets/hogwarts3.jpg"); */
  /* background-image: url("/src/assets/nether.jpg"); */
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 100%;
  border-bottom: 3px ridge rgb(128, 45, 2);
  background-size: cover;
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url("/src/assets/dirt.jpg"); */
 background-color: rgba(18, 18, 18, 0.8);
  /* background-color: rgba(107, 162, 245, 0.547); */
  width: 50%;
  max-width: 450px;
  /* border: 2px solid white; */
  box-shadow: 3px 3px 10px rgb(128, 45, 2); 
  /* box-shadow: 3px 3px 10px black; */
}

p, ol, h1, h2, h3 {
  /* color: #c7c7c7; */
  color: white;
}

p {
  font-size: 20px;
  margin-top: 0px;
}

ol {
  display: flex;
  flex-direction: column;  
  align-items: baseline;
}
</style>
